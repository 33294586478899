.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*Hide Counter Buttons when type is numeric */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}

.main-content {
  display: flex;
  flex-wrap: wrap;
}

.common-left-sidebar {
  width: 265px;
  height: 100vh;
  position: fixed;
  flex: 0 0 auto;
}

.common-content-part {
  width: 100%;
  padding: 34px 20px;
  margin-left: 265px;
  min-height: calc(100vh - 100px);
}
.footer-part {
  margin-left: 265px;
  padding: 0 20px 7px;
}
.footer-part span.MuiTypography-root {
  color: #89878a;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.36px;
  margin: 0 7px 0 0;
}
.footer-part a {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.36px;
  color: #437de3;
  text-decoration: none;
  margin: 0 7px 0 0;
}

.footer-powered {
  /* margin-left: 0; */
  /* padding-right: 24px; */
  text-align: center;
  margin: 20px 0;
}

.footer-powered .MuiButtonBase-root {
  background: rgba(92, 91, 230, 0.1);
  border: none;
  font-size: 16px;
  padding: 15px 10px;
  height: auto;
  border-radius: 100px;
}

.footer-powered span.MuiTypography-root {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
}

.footer-powered a {
  color: #5c5be6;
  text-decoration: none;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}

.footer-powered a span {
  color: #16c73d;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}

.MuiModal-root {
  margin: 15px;
}
/* Google Map Autocomplete box */
.pac-container {
  width: 100% !important;
  max-width: 400px !important;
  z-index: 1300;
}

.custom_status_filter_list.MuiPaper-rounded {
  border-radius: 5px;
  border: 1px solid #89878a;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: -10px;
  margin-left: -10px;
}
.custom_status_filter_list ul {
  padding: 8px;
}
.custom_status_filter_list li.MuiButtonBase-root {
  padding: 0 0 3px 0;
  background: transparent;
}
.custom_status_filter_list li.MuiButtonBase-root.Mui-selected:hover {
  background: transparent;
}
.custom_status_filter_list li span.MuiButtonBase-root.MuiCheckbox-root {
  padding: 0;
}
.custom_status_filter_list li span.MuiTypography-body1 {
  color: #89878a;
  font-size: 18px;
  letter-spacing: -0.36px;
  padding: 0 7px;
}
.custom_status_filter_list li svg {
  fill: #5c5be6;
}
.custom_status_filter_list li.accepted span.MuiTypography-body1 {
  color: #f10a0a;
}
.custom_status_filter_list li.offered span.MuiTypography-body1 {
  color: #ee10bd;
}
.custom_status_filter_list li.partpaid span.MuiTypography-body1 {
  color: #0969d9;
}
.custom_status_filter_list li.confirmed span.MuiTypography-body1 {
  color: #f59d19;
}
.custom_status_filter_list li.paid span.MuiTypography-body1 {
  color: rgba(8, 170, 43, 0.96);
}

.payment_custom_status_filter_list.MuiPaper-rounded {
  background: #fff;
  border: 1px solid #89878a;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-top: -10px;
  margin-left: -10px;
}
.payment_custom_status_filter_list ul {
  padding: 8px;
}
.payment_custom_status_filter_list ul li {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.28px;
  line-height: normal;
  color: #89878a;
  padding: 6px;
  border-radius: 6px;
}
.payment_custom_status_filter_list ul li.Mui-selected {
  font-weight: 700;
  background: transparent;
}
.payment_custom_status_filter_list ul li.accepted {
  color: #f10a0a;
}
.payment_custom_status_filter_list ul li.offered {
  color: #ee10bd;
}
.payment_custom_status_filter_list ul li.partpaid {
  color: #0969d9;
}
.payment_custom_status_filter_list ul li.confirmed {
  color: #f59d19;
}
.payment_custom_status_filter_list ul li.paid {
  color: rgba(8, 170, 43, 0.96);
}
.payment_custom_status_filter_list ul li.Mui-selected:after {
  content: '✓';
  position: absolute;
  right: 0;
}
.payment_custom_status_filter_list ul li:hover {
  background: #f3f3f3;
}
.payment_custom_status_filter_list ul li.Mui-selected,
.payment_custom_status_filter_list ul li.Mui-selected:hover {
  background: #fff !important;
}
.flag_icon svg {
  width: 25px;
  height: 25px;
  margin-left: 5px;
}
.flag_icon p {
  min-width: 20px;
}
.footerlink:hover {
  text-decoration: underline;
}
@media screen and (max-width: 991px) {
  .common-left-sidebar {
    width: 100%;
    height: auto;
    position: unset;
    flex: 0 0 auto;
  }
  .common-content-part {
    margin-left: 0;
    padding: 15px 0;
  }
  .footer-part {
    padding: 10px 20px 20px;
    margin-left: 0;
  }
  .footer-powered {
    /* padding: 10px 20px 20px; */
    margin-left: 0;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .footer-part span.MuiTypography-root {
    font-size: 16px;
  }
  .footer-part a {
    font-size: 16px;
  }

  .footer-powered span.MuiTypography-root {
    font-size: 16px;
  }

  .footer-powered a {
    font-size: 16px;
  }
}
